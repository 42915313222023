.survey-page {
  height: inherit;
  display: flex;
  flex-direction: column;

  .question-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .content-container {
    height: calc(100% - 125px);
    padding: 35px 25px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .variables-container {
    label {
      font-size: 17px;
    }

    .ant-checkbox .ant-checkbox-inner {
      -ms-transform: scale(1.5);
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }

    .ant-radio .ant-radio-inner {
      -ms-transform: scale(1.8);
      -webkit-transform: scale(1.8);
      transform: scale(1.8);
    }

    .ant-input {
      border-bottom: 1px solid lightgray;
    }

    .custom-answer {
      margin-top: 50px;
    }

    .checkbox {
      display: flex;
      flex-direction: column;

      .ant-checkbox-wrapper {
        margin-inline-start: 0 !important;
      }

      .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover,
      .ant-checkbox-inner,
      .ant-checkbox-inner {
        border-color: #e0e0e0;
      }
      .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
        border: none;
      }
    }

    .ant-checkbox-group-item {
      margin-right: 0;
      margin-bottom: 20px;
    }

    .required-mark {
      font-size: 20px;
      color: red;
      margin-right: 5px;
    }

    .custom-component {
      display: flex;
      align-items: center;

      .upload-container {
        display: grid;
        width: 100%;

        .ant-upload-wrapper {
          .ant-upload-list {
            margin-bottom: 20px;

            .anticon-delete {
              color: red;
            }
            .ant-upload-list-item-action {
              opacity: 1;
            }
          }
        }
      }

      input {
        font-size: 16px;
      }
    }
  }

  .btn-container {
    width: 100%;
    margin-top: auto;

    .ant-btn {
      width: 100%;
      border-radius: 12px;
      height: 44px;

      span {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: black;
      }
    }
  }

  .csi-template {
    .csi-item {
      display: grid;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(211, 211, 211, 0.15);

      .criteria-container {
        margin-bottom: 20px;

        .criteria-item {
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          text-align: center;

          h3 {
            font-weight: 400;
            margin: 0;
            padding: 0;
            font-size: 15px;
          }

          .criteria-select {
            margin-left: auto;
            background: rgba(17, 17, 20, 0.04);
            border-radius: 10px;
            min-width: 80px;
            max-width: 80px;

            .ant-select-selector {
              height: 40px;
              align-items: center;
            }

            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .nps-template {
    .grade-text {
      display: flex;

      span:first-child {
        min-width: 40px;
      }

      span {
        padding: 4px 0;
      }
    }

    .lowest-highest-text-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;

      .lowest-text {
        overflow: hidden;
        width: 35%;
        overflow-wrap: break-word;
      }

      .highest-text {
        overflow: hidden;
        width: 35%;
        overflow-wrap: break-word;
        text-align: right;
      }
    }

    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: 10px;
    }
    .ant-slider-dot {
      inset-block-start: -1px;
      width: 12px;
      height: 12px;
    }
    .ant-slider-handle {
      inset-block-start: 4px;
      width: 12px;
      height: 12px;
    }
    .ant-slider-handle::after {
      inset-block-start: -2px;
      inset-inline-start: 0;
      width: 13px;
      height: 13px;
      background-color: #ffffff;
      box-shadow: 0 0 0 2px var(--app-color);
    }
  }
}

.ant-tooltip-inner {
}

.rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.loyalty-button-group {
  display: flex;
  margin-bottom: 10px;

  .loyalty-button {
    flex-grow: 1;
    font-size: 16px;
    padding: 4px 8px;
    height: 44px;
  }
}

@media (max-width: 768px) {
  .loyalty-button {
    font-size: 14px;
    padding: 3px 6px;
    height: 40px;
  }
}

@media (max-width: 576px) {
  .loyalty-button {
    font-size: 12px;
    padding: 2px 4px;
    height: 36px;
  }
}

@media (max-width: 400px) {
  .loyalty-button {
    font-size: 8px;
    padding: 1px 2px;
    height: 25px;
  }
}

.ant-table-row {
  touch-action: none;
}

.ant-table-cell {
  touch-action: none;
}
