* {
  &:not(.disable-global-font) {
    font-family: 'Golos text', sans-serif;
  }
}

//::-webkit-scrollbar {
//  width: 15px;
//}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  border-radius: 100px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #697a7f;
}
