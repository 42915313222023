.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;

  .logo {
    height: auto;
    max-width: 40%;
    max-height: 77%;
    margin-left: 20px;

    img {
      height: 50px;
    }
  }
}

.header-container {
  height: 70px;
  .anticon-check-circle {
    font-size: 16px;
  }

  .ant-progress-line {
    margin-inline-end: 0;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;

  h4 {
    font-weight: 400;
    margin: 0;
  }
  .link {
    font-weight: 400;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    color: #00aa5c;
  }
}
.language-changer {
  margin-left: auto;
  margin-right: 20px;
  border-radius: 5px;

  .button {
    display: flex;
    align-items: center;
    background: var(--app-color);
    color: var(--inverted-color);

    .lang-label {
      font-weight: 500;
      margin-right: auto;
    }
  }

  .ant-select-arrow {
    color: var(--inverted-color);
  }

  span {
    font-weight: 500;
  }
  .ant-select-selector {
    .ant-select-selection-item {
      color: var(--inverted-color);
    }
  }

  .ant-select-arrow {
    font-size: 15px;
    height: 11px;
  }
}

.masked-input:focus {
  border-bottom: 1px solid var(--app-color) !important;
}

.masked-input {
  border-bottom: 1px solid lightgray !important;
}

.upload-next-btn-container {
  width: 100%;
  margin: 50px 0;

  .ant-btn {
    width: 100%;
    border-radius: 12px;
    height: 44px;

    span {
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      color: black;
    }
  }
}

.error-page-layout {
  gap: 40px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .description {
    padding: 0 30px;
    display: flex;
    text-align: center;
  }
  .image {
    padding: 0 30px;
  }
  .image img {
    width: 100%;
    max-width: 600px;
    max-height: 600px;
  }
}

.markdown-content {
  font-family: inherit !important;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6,
.markdown-content p,
.markdown-content li,
.markdown-content a {
  font-family: inherit !important;
  margin-bottom: 0;
  line-height: 1.2;
}

strong {
  font-weight: bold;
}
